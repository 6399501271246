.functionTitle {
  padding-left: 5px;
  border-left: 5px solid rgb(0, 106, 93);
  text-align: left;
}

.existSubmitOrConfirmed{
  background-color: #ffff00 !important;
}

.existNotSubmited{
  background-color: #039be5 !important;
}

.existNotSubmitedColor{
  color: white !important;
}

.criteriaSearchFieldLeft{
  max-width: 700px;
  margin-right:4px;
  float: left;
}

.criteriaSearchDateLeft{
  margin-right:4px;
  float: left;
}

.criteriaSearchDatePart{
  margin-right:4px;
}

.criteriaSearchFieldRight{
  margin-right:4px;
  float: right;
}

.columnAlignLeft{
  text-align: left;
}

.duration {
  float: left;
  display: flex;
  flex-direction: column;
}

.durationSpan {
  margin-right:10px;
  margin-top:5px;
  float: left;
}

.calendarAnnotation {
  font-size: 0.9em;
  margin-top: -4px;
  margin-bottom: 10px;
  text-align: left;
}