.functionTitle {
  padding-left: 5px;
  border-left: 5px solid rgb(0, 106, 93);
  text-align: left;
}

.existSubmitOrConfirmed{
  background-color: #ffff00 !important; 
}

.criteriaSearchFieldLeft{
  margin-left:4px;
  float: left;
}

.criteriaSearchFieldRight{
  margin-right:4px;
  float: right;
}

.columnAlignLeft{
  text-align: left;
}

.duration {
  float: left;
  display: flex;
  flex-direction: column;
}

.durationSpan {
  margin-right:10px;
  margin-top:5px;
  float: left;
}

.statusWorkTimeGrid {
  display: grid;
  float: left;
}

.statusWorkTimeTopLeft {
  grid-row: 1;
  grid-column: 1;
}

.statusWorkTimeTopRight {
  grid-row: 1;
  grid-column: 2;
}

.statusWorkTimeBottomLeft {
  grid-row: 2;
  grid-column: 1;
}

.statusWorkTimeBottomRight {
  grid-row: 2;
  grid-column: 2;
}

.statusWorkTimeLeft {
  text-align: right;
  font-size: 0.9em;
}

.statusWorkTimeRight {
  text-wrap: balance;
  text-align: left;
  margin-top: -4px
}

.calendarAnnotation {
  font-size: 0.9em;
  margin-top: -4px;
  margin-bottom: 10px;
  text-align: left;
}