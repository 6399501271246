.day {
  padding: 0px 0px !important;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.working {
  white-space: nowrap;
}

.invalidQualification {
  border-top: 1px dashed rgba(128, 106, 106, 0.4);
  background: rgba(128, 106, 106, 0.2) !important;
  white-space: nowrap;
  color:rgba(0, 0, 0, .54);
}

.invalidQualificationOutside {
  font-size: 85%;
}

.invalidQualificationInOutTime {
  background: rgba(128, 106, 106, 0.2) !important;
  white-space: nowrap;
  color:rgba(0, 0, 0, .54);
}

.contentCenter {
  text-align: center !important;
}

.contentRight {
  text-align: right !important;
}

.totalWorkingValue {
  text-align: right !important;
}

.workingRecordTitle {
  border-bottom: 1px dashed rgba(128, 106, 106, 0.4) !important;
  min-width: 1050px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.displayItem {
  min-width: 110px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.displayItemRed {
  min-width: 100px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.projectName {
  min-width: 300px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
  white-space: nowrap;
}

.status {
  min-width: 92px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.worker {
  min-width: 114px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.firstPartner {
  min-width: 115px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
  white-space: nowrap;
}

.topHierarchyLevel {
  min-width: 80px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
  white-space: nowrap;
  text-align: right !important;
}

.item {
  min-width: 110px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.headerTitle {
  font-weight: bold;
  margin-left:20px !important;
}

.displayItem,.projectName, .status, .worker, .firstPartner, .item, .workingRecordTitle > td {
  padding: 0px 0px 0px 2px !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.searchGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 10px;
  font-size:1.2em !important;
  flex-wrap: wrap;
}

/* criteria 入力項目欄の設定 */
.criteriaInputField {
  float:left;
  width:810px;
 }

 /* criteria 検索項目欄の設定 */
.criteriaSearchField {
 float:right;
}

.leftText {
 text-align: left;
}

.rowRed {
  background: #f8e0e6 !important ;
}

.rowBlue {
background: #e3e9f5 !important ;
}

.flexContainer {
  display:flex;
}

.flexCell {
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.materialSearchButton {
  width: 20px;
  height: 16px;
  background: rgba(0, 0, 0, .54);
  color: rgb(255, 255, 255);
  border-radius: 2px;
  padding: 0px;
  margin: 3px;
}

.materialSearchButton i {
  font-size: 16px;
}

.loupeCell {
  min-width: 30px;
  width: 30px;
}